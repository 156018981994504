import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  TemplateRef,
} from "@angular/core";
import {
  CometChatService,
  MessageStatus,
  IChatMessage,
} from "../../services/comet-chat.service";
import { DomSanitizer } from "@angular/platform-browser";
import moment from "moment";
import { CometChat } from "@cometchat-pro/chat";
import { FirebaseServiceService } from "src/app/services/firebase-service.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { GlobalService } from "src/app/services/global.service";
import { LoggingService } from "src/app/services/logging.service";

@Component({
  selector: "app-chat-view",
  templateUrl: "./chat-view.component.html",
  styleUrls: ["./chat-view.component.scss"],
})
export class ChatViewComponent {
  public messages: any[] = [];
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  @ViewChild("conversation")
  private conversationContainer: ElementRef;

  @Input() consultants;

  @ViewChild("uploadInput")
  private uploadInput: ElementRef;
  private file: any;
  private filePreview: any | null = null;
  public chatMsg: string = "";
  public receiverId: string;
  currentUserName: string = "";
  currentUserID = this.chatService.userId.toLowerCase();
  public personTyping: boolean = false;
  private currentGroupId: string;

  constructor(
    private chatService: CometChatService,
    public sanitizer: DomSanitizer,
    private fb: FirebaseServiceService,
    private modalService: BsModalService,
    private loggingService: LoggingService
  ) {}

  ngOnInit(): void {
    this.currentGroupId = this.chatService.activeGroupId;
    let firstName: string = this.fb.activeUserName.substr(
      0,
      this.fb.activeUserName.indexOf(" ")
    );
    // Extract first name
    if (firstName === "") {
      // If only one name and no surname
      this.currentUserName = this.fb.activeUserName;
    } else {
      this.currentUserName = firstName;
    }
    this.chatService.getMessages().subscribe(
      (message) => {
        if (message.uid.toLowerCase() === this.currentUserID) {
          this.currentUserName = message.name;
        }
        this.messages.push({
          ...message,
        });
        console.log(this.messages)
      },
      (error) => {
        this.loggingService.updateLogBatch(
          JSON.stringify(error),
          this.currentGroupId
        );
      }
    );
    this.chatService.messageListener(
      (msg, error) => {
        if (error) {
          this.loggingService.updateLogBatch(
            JSON.stringify(error),
            this.currentGroupId
          );
          return console.log(`error: ${error}`);
        }
        if (msg.receiver.guid === this.currentGroupId) {
          this.messages = [...this.messages, this.chatService.setMessage(msg)];
          CometChat.markAsRead(msg);
        }
      },
      (typing, event) => {
        if (event.receiverId === this.currentGroupId) {
          this.personTyping = typing;
          setTimeout(() => {
            if(typing) this.personTyping = false;
          }, 2000);
        }
      },
      (messageReceipt, messageStatus) => {
        this.messages.forEach((msg: IChatMessage, index: number) => {
          if (messageReceipt.messageId === msg.id) {
            this.messages[index].status = messageStatus;
          }
        });
      }
    );
  }

  openModal(template: TemplateRef<any>, event: any) {
    this.file = event.target.files[0];
    let reader: FileReader = new FileReader();
    reader.onload = (e) => {
      this.filePreview = reader.result;
    };
    reader.readAsDataURL(this.file);
    this.modalRef = this.modalService.show(template, this.config);
  }

  confirm(): void {
    this.sendMediaMessage();
    this.modalRef.hide();
  }

  decline(): void {
    this.filePreview = null;
    this.modalRef.hide();
  }

  sendMessage() {
    this.chatService.sendMessage(
      this.chatService.activeGroupId,
      this.chatMsg,
      () => {
        let msg = {
          sender: {
            name: this.currentUserName,
            uid: this.currentUserID,
          },
          id: this.messages[this.messages.length - 1]
            ? (
                parseInt(this.messages[this.messages.length - 1].id) + 1
              ).toString()
            : null,
          text: this.chatMsg,
          data: {
            entities: {
              sender: {
                entityType: "text",
              },
            },
          },
          status: MessageStatus.Sent,
          receiver: "tempfakeid",
          sentAt: moment().unix(),
        };
        this.messages = [...this.messages, this.chatService.setMessage(msg)];
        this.chatMsg = "";
      }
    );
  }

  sendMediaMessage() {
    this.chatService.sendMediaMessage(
      this.chatService.activeGroupId,
      this.file,
      () => {
        let msg = {
          sender: {
            name: this.currentUserName,
            uid: this.currentUserID,
          },
          id: "tempfakeid",
          text: this.chatMsg,
          data: {
            entities: {
              sender: {
                entityType: "text",
              },
            },
            attachments: [{
              extension: this.file.type.split("/").pop(),
              url: this.sanitizer.bypassSecurityTrustResourceUrl(
                URL.createObjectURL(this.file)
              ),
            }],
          },
          receiver: "tempfakeid",
          sentAt: moment().unix(),
        };
        this.messages = [...this.messages, this.chatService.setMessage(msg)];
        //this.uploadInput.nativeElement.value = "";
      }
    );
  }

  deleteMessage(msgId: string) {
    // Figure out how to delete recently typed msgs
    this.messages = this.messages.filter((message) => message.id !== msgId);
    this.chatService.deleteMessage(msgId);
  }

  ngOnDestroy() {
    this.chatService.clearChats();
  }
}
