import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { Observable, timer, Subscription } from "rxjs";
import * as moment from "moment";
import { FirebaseServiceService } from "src/app/services/firebase-service.service";
import {
  CometChatService,
  MessageStatus,
  IChatMessage,
} from "src/app/services/comet-chat.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { CometChat } from "@cometchat-pro/chat";
import { GlobalService } from "src/app/services/global.service";
import { LoggingService } from "src/app/services/logging.service";

export interface IChatsItem {
  $key: string;
  activeChat: boolean;
  botInProgress: boolean;
  caseNumber: string;
  chatOptions: IChatOption[];
  chatType: ChatType;
  connectionLost: boolean;
  createdDate: string;
  consultantID: string;
  dependantCode: string;
  emergency: boolean;
  firstTopic: string;
  hasAttachments: true;
  idNumber: string;
  itemFormattedDate: string;
  language: string;
  parsedCreatedDate: IChatDate;
  policyNumber: string;
  status: ChatStatus;
  unread?: number;
  userName?: string;
  internalSupervisor?: string;
}

interface IChatOption {
  checked: boolean;
  option: string;
}

interface IChatDate {
  date: number;
  day: number;
  hours: number;
  minutes: number;
  month: number;
  seconds: number;
  time: number;
  timezoneOffset: number;
  year: number;
}

enum ChatType {
  Nurse = "NURSE",
}

enum ChatStatus {
  Open = "open",
  Closed = "closed",
}

@Component({
  selector: "app-chats",
  templateUrl: "./chats.component.html",
  styleUrls: ["./chats.component.scss"],
})
export class ChatsComponent implements OnInit {
  @ViewChild("conversationInternal")
  private conversationInternalContainer: ElementRef;
  private subscription: Subscription;
  private unreadTicker: Subscription;
  @Output() TimerExpired: EventEmitter<any> = new EventEmitter<any>();
  @Input() SearchDate: moment.Moment = moment();
  @Input() ElapsTime: number = 1;
  searchEndDate: moment.Moment;
  remainingTime: number;
  minutes: number | null = null;
  seconds: number | null = null;
  timerProgress: number | null = null;
  everySecond: Observable<number> = timer(0, 1000);
  everyFiveSeconds: Observable<number> = timer(0, 10000);
  isFirstOpen = true;
  items: Array<IChatsItem>;
  internalChatItems: IChatMessage[];
  queues: Array<any>;
  consultants: Array<any>;
  chatID = {
    data: "yo",
  };
  user: any = {};
  item: any[];
  recipientName: string;
  loadingChat: boolean | null;
  setGroup: string = "";
  chatStart: string | null = null;
  chatTopic: string | null = null;
  chatCaseId: string | null = null;
  chatPolicyNumber: string | null = null;
  chatTimeSince: string | null = null;
  internalChatMsg: string = "";
  selected: string = "";
  questionsAdmin: any[];
  questionsNurse: any[];
  setQueueQuestion: string = "";
  internalChatSupervisor: string | null = null;

  constructor(
    private ref: ChangeDetectorRef,
    private fb: FirebaseServiceService,
    private chatService: CometChatService,
    private firebaseAuth: AngularFireAuth,
    private globalService: GlobalService,
    private loggingService: LoggingService
  ) {
    this.firebaseAuth.authState.subscribe(
      (auth) => {
        if (auth) {
          this.chatService.userId = auth.uid;
          this.getConsultant(auth.uid).then(() => {
            this.signIn(this.chatService.userId);
          });
        }
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
    this.searchEndDate = this.SearchDate.add(this.ElapsTime, "minutes");

    const fetch = window.fetch;
    window.fetch = (...args) =>
      (async (args) => {
        var result = await fetch(...args);
        if (result.status === 401 || result.status === 404) {
          //console.clear();
          console.log("Logging Active:");
          this.loggingService.updateLogBatch(JSON.stringify(fetch));
        }
        return result;
      })(args);
  }

  ngOnInit() {
    this.loadingChat = null;
    this.getCues();
    this.getAllConsultants();
    this.getQuestions();
    this.getData();
  }

  setSelectedChat(key) {
    this.selected = key;
  }

  signIn(userId: string): void {
    this.chatService.login(userId).subscribe(
      (result) => {},
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
  }

  removeUserAssist() {
    this.fb.removeUserAssist(this.globalService.user.uid);
    delete this.globalService.user.assist;
  }

  setInternalConsultant(consultantId: string) {
    this.internalChatSupervisor = consultantId;
    let currentChat: string;
    this.items.forEach((chat) => {
      if (chat.$key === this.chatService.activeGroupId) {
        currentChat = chat.userName || chat.$key;
        this.fb.assignSupervisor(chat, this.internalChatSupervisor);
        this.fb.updateUserAssist(chat, consultantId);
      }
    });
    let msg: string = `${this.fb.activeUserName} needs your assistance in chat: ${currentChat}`;
    this.isFirstOpen = false;
    let userGroupUid: string = this.chatService.createDirectUserChatGroupUid(
      this.internalChatSupervisor,
      this.chatService.userId
    );
    this.chatService.sendMessage(userGroupUid, msg);
  }

  getInternalConsultantName(uid: string) {
    let consultantName: string = "Internal Chat";
    if (this.consultants) {
      this.consultants.forEach((consultant) => {
        if (consultant.uid === uid) {
          consultantName += `: (${consultant.name})`;
        }
      });
    }
    return consultantName;
  }

  checkIfSupervisorsAvailable() {
    return (
      this.consultants.filter(
        (consultant) =>
          consultant.role === "Supervisor" &&
          consultant.availability &&
          consultant.$key !== this.chatService.userId
      ).length === 0
    );
  }

  getPreviousUserMessages(uid: string) {
    this.chatService.activeUserChatId = uid;
    this.chatService.currentConsultantState = true;
    this.loadingChat = true;
    let userGroupUid = this.chatService.createDirectUserChatGroupUid(
      this.chatService.activeUserChatId,
      this.chatService.userId
    );
    CometChat.getGroup(userGroupUid)
      .then((group: any) => {
        // Check if the user has already joined the group
        if (group.hasJoined) {
          // Get msgs
          this.chatService
            .getPreviousMessages(userGroupUid)
            .then((results: any[]) => {
              this.loadingChat = false;
              this.checkAutoTimerStart();
              this.chatStart = moment
                .unix(
                  results.filter((msg) => msg.category === "message")[0]
                    ? results.filter((msg) => msg.category === "message")[0]
                        .sentAt
                    : ""
                )
                .format("ddd, HH:mm");
              results.map((message) => {
                if (message.category === "message") {
                  CometChat.markAsRead(message);
                  this.chatService.messages$.next(
                    this.chatService.setMessage(message)
                  );
                }
              });
              //console.log("Chat Results:", results);
            })
            .catch((error) => {
              this.loggingService.updateLogBatch(
                JSON.stringify(error),
                userGroupUid
              );
              console.log("error:", error);
            });
        } else {
          this.joinGroup(userGroupUid);
        }
      })
      .catch((error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
        switch (error.code) {
          case "ERR_GROUP_NOT_JOINED":
            this.joinGroup(userGroupUid);
            break;
          case "ERR_GUID_NOT_FOUND":
            this.createUserGroup(userGroupUid, () =>
              this.getPreviousUserMessages(uid)
            );
        }
        //console.log("Group details fetching failed with exception:", error);
      });
  }

  getInternalChatSupervisor() {
    this.items.forEach((chat) => {
      if (chat.$key === this.chatService.activeGroupId) {
        this.internalChatSupervisor = chat.internalSupervisor || null;
      }
    });
  }

  getPreviousMessages(chat: any, groupQueue: boolean = false) {
    if (chat.$key === undefined) {
      chat.$key = chat.caseNumber;
    }
    this.chatService.activeChat = chat;
    const groupId: string = chat.$key;
    if (this.subscription) this.subscription.unsubscribe();
    this.chatService.activeGroupId = groupId;
    this.chatService.currentConsultantState = null;
    this.chatService.activeChatStatus = chat.status;
    this.chatService.activeConsultantId = chat.consultantID
      ? chat.consultantID
      : null;
    this.loadingChat = true;
    this.chatStart = null;
    this.minutes = null;
    this.seconds = null;
    this.chatTopic = chat.firstTopic;
    this.chatCaseId = chat.$key;
    this.chatPolicyNumber = chat.policyNumber;
    this.chatTimeSince = this.timeSince(chat.createdDate);
    if (chat.internalChat === undefined) {
      const newKey: string = this.fb.getInternalNewChatKey();
      if (newKey && chat.$key !== undefined) {
        this.fb.activeInternalChat = this.fb.addInternalChatLink(
          groupQueue,
          chat,
          newKey
        );
      }
    } else {
      this.fb.activeInternalChat = chat.internalChat;
    }
    CometChat.getGroup(groupId)
      .then((group: any) => {
        // Check if the user has already joined the group
        if (group.hasJoined) {
          // Get msgs
          this.chatService
            .getPreviousMessages(groupId)
            .then((results: any[]) => {
              this.loadingChat = false;
              this.checkAutoTimerStart();
              this.chatStart = moment
                .unix(
                  results.filter((msg) => msg.category === "message")[0]
                    ? results.filter((msg) => msg.category === "message")[0]
                        .sentAt
                    : ""
                )
                .format("ddd, HH:mm");
              this.getInternalChat();
              this.getInternalChatSupervisor();
              if (results[results.length - 1].action === "left") {
                this.chatService.activeChatStillActive = false;
              } else {
                this.chatService.activeChatStillActive = true;
              }
              results.map((message) => {
                if (message.category === "message") {
                  CometChat.markAsRead(message);
                  this.chatService.messages$.next(
                    this.chatService.setMessage(message)
                  );
                }
              });
              //console.log("Chat Results:", results);
            });
        } else {
          this.joinGroup(groupId);
        }
      })
      .catch((error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error), groupId);
        if (error.code === "ERR_GROUP_NOT_JOINED") {
          this.joinGroup(groupId);
        }
        //console.log("Group details fetching failed with exception:", error);
      });
  }

  createUserGroup(groupId: string, callback: Function = null) {
    // TODO: ADD USER GROUP CREATE FUNCTION FROM

    let groupName = "Supervisor Chat - " + groupId;
    let groupType = CometChat.GROUP_TYPE.PUBLIC;
    let password = "";
    let group = new CometChat.Group(groupId, groupName, groupType, password);

    CometChat.createGroup(group)
      .then((group) => {
        this.loggingService.updateLogBatch(
          JSON.stringify(group),
          (group as any).guid
        );
        console.log("Group created successfully:", group);
        this.joinGroupOnly((group as any).guid);
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        this.loggingService.updateLogBatch(
          JSON.stringify(error),
          (group as any).guid
        );
        console.log("Group creation failed with exception:", error);
      });
  }

  joinGroup(groupId: string) {
    // Otherwise make user join the group to access msgs
    CometChat.joinGroup(
      groupId,
      CometChat.GROUP_TYPE.PUBLIC as CometChat.GroupType,
      ""
    )
      .then((group) => {
        this.setGroup = groupId;
        //console.log("Group joined successfully:", group);
        // Fetch and display msgs
        this.chatService.getPreviousMessages(groupId).then((results: any[]) => {
          this.loadingChat = false;
          this.getInternalChat();
          results.map((message) => {
            if (message.category === "message") {
              this.chatService.messages$.next(
                this.chatService.setMessage(message)
              );
              //console.log(this.chatService.messages$);
            }
          });
        });
      })
      .catch((error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error), groupId);
        if (error.code === "ERR_GROUP_JOIN_NOT_ALLOWED") {
          let GUID = groupId;
          let membersList = [
            new CometChat.GroupMember(
              groupId,
              CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT
            ),
          ];

          CometChat.addMembersToGroup(GUID, membersList, []).then(
            (response) => {
              console.log("response", response);
            },
            (error) => {
              console.log("Something went wrong", error);
            }
          );
        }
      });
  }

  joinGroupOnly(groupId: string) {
    // Otherwise make user join the group to access msgs
    CometChat.joinGroup(
      groupId,
      CometChat.GROUP_TYPE.PUBLIC as CometChat.GroupType,
      ""
    )
      .then((group) => {
        this.loggingService.updateLogBatch(JSON.stringify(group), groupId);
        console.log("Group joined successfully.");
      })
      .catch((error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error), groupId);
        //console.error("Couldn't Join Group:", groupId, error);
        if (error.code === "ERR_GROUP_JOIN_NOT_ALLOWED") {
          this.createUserGroup(groupId);
        }
      });
  }

  addGroupMember(groupId: string) {
    CometChat.getUser(groupId.toLowerCase())
      .then((user) => {
        this.loggingService.updateLogBatch(
          JSON.stringify(user),
          groupId.toLowerCase()
        );
        console.log("User details fetched for user:", user);
        let membersList = [
          new CometChat.GroupMember(
            (user as any).uid,
            CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT
          ),
          new CometChat.GroupMember(
            this.chatService.userId.toLowerCase(),
            CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT
          ),
        ];
        CometChat.addMembersToGroup(groupId, membersList, [])
          .then((response) => {
            console.log("response", response);
            this.joinGroupOnly(groupId.toLowerCase());
          })
          .catch((error) => {
            this.loggingService.updateLogBatch(
              JSON.stringify(error),
              groupId.toLowerCase()
            );
            console.log("Something went wrong", error);
          });
      })
      .catch((error) => {
        this.loggingService.updateLogBatch(
          JSON.stringify(error),
          groupId.toLowerCase()
        );
        console.log("User details fetching failed with error:", error);
      });
  }

  leaveGroup() {
    if (this.chatService.activeGroupId) {
      // Clear key on leave
      let groupTimerKey: string = `chat_${this.chatService.activeGroupId}`;
      localStorage.removeItem(groupTimerKey);
      this.chatService.leaveGroup(this.chatService.activeGroupId, () => {
        this.closeChat();
      });
    }
  }

  stopBot() {
    this.fb.stopBot(this.selected);
  }

  closeChat() {
    const r = confirm("Are you sure that you what to close this chat?");
    if (r === true) {
      this.loadingChat = null;
      if (this.subscription) this.subscription.unsubscribe();
      this.minutes = null;
      this.seconds = null;
      this.chatTopic = null;
      this.chatCaseId = null;
      this.chatPolicyNumber = null;
      this.chatTimeSince = null;
      this.fb.activeInternalChat = null;
      this.fb.setCallbackChat(this.chatService.activeGroupId, false);
      this.fb.setEmergencyChat(this.chatService.activeGroupId);
      this.addedAttachments(this.selected);
    } else {
      console.log("this chat has been closed");
    }
  }

  addedAttachments(chat) {
    const r = confirm("Did you generate a script or sick note?");
    if (r === true) {
      //console.log(chat);
      this.fb.chatHasAttachments(chat, true);
      this.fb.closeChats(chat);
    } else {
      //console.log(chat);
      this.fb.chatHasAttachments(chat, false);
      this.fb.closeChats(chat);
    }
  }

  joinChat() {
    this.setConsultant(this.selected);
    this.chatService.activeConsultantId = this.selected;
    this.fb.joinChat(this.chatService.activeGroupId);
  }

  unassignChat() {
    this.chatService.activeConsultantId = null;
    this.fb.unsetConsultant(this.chatService.activeGroupId);
  }

  sendInternalChat() {
    if (this.internalChatMsg.length !== 0) {
      let firstName: string = this.fb.activeUserName.substr(
        0,
        this.fb.activeUserName.indexOf(" ")
      );
      let message: IChatMessage = {
        name: firstName,
        uid: this.chatService.userId,
        message: this.internalChatMsg,
        time: moment.unix(moment().unix()).format("ddd, HH:mm"),
      };
      this.fb.pushInternalChatMessage(message, this.fb.activeInternalChat);
      this.internalChatMsg = "";
    }
  }

  getData() {
    this.fb.getChats().subscribe(
      (result) => {
        this.items = result.map((item) => {
          let emergency: boolean = false;
          if (
            item.payload.val().firstTopic === "I have an Emergency" ||
            item.payload.val().emergency
          ) {
            emergency = true;
          }
          return {
            $key: item.key,
            ...item.payload.val(),
            emergency: emergency,
          };
        });
        this.items.sort((a, b) => {
          var keyA = new Date(a.createdDate),
            keyB = new Date(b.createdDate);
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        this.items = this.items.reverse();
        this.constantUnreadMsgCountCheck();
        this.checkIfChatStillOpen();
        this.globalService.connectionLostChatsIds = [];
        this.items
          .filter((chat) => chat.connectionLost)
          .forEach((chat) =>
            this.globalService.updateDisconnectedIds(chat.$key)
          );
        // console.log({
        //   queues: this.items.filter((chat) => chat.status !== "closed"),
        // });
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
  }

  checkIfChatStillOpen() {
    let onlyOpenChats: IChatsItem[] = this.items.filter(
      (item) => item.status !== ChatStatus.Closed
    );
    if (onlyOpenChats.length === 0) {
      this.chatService.activeChatStillActive = false;
    }
    for (let i = 0; i < onlyOpenChats.length; i++) {
      if (onlyOpenChats[i].$key === this.chatService.activeGroupId) {
        this.chatService.activeChatStillActive = true;
        return;
      }
      this.chatService.activeChatStillActive = false;
    }
  }

  getInternalChat() {
    this.fb.getInternalChat(this.fb.activeInternalChat).subscribe(
      (result) => {
        this.internalChatItems = [];
        this.internalChatItems = result.map((item) => {
          return {
            ...item.payload.val(),
          };
        });
        setTimeout(() => {
          if (this.conversationInternalContainer) {
            this.conversationInternalContainer.nativeElement.scrollTop = this.conversationInternalContainer.nativeElement.scrollHeight;
          }
        }, 100);
        //console.log({ internal: this.internalChatItems });
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
  }

  getCues() {
    this.fb.getQueues().subscribe(
      (result) => {
        this.queues = result.map((item) => {
          return {
            $key: item.key,
            ...item.payload.val(),
          };
        });
        //console.log({ groups: this.queues });
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
  }

  isAssigned(members: any) {
    if (members) {
      if (members[this.chatService.userId] !== undefined) return true;
    }
    return false;
  }

  getAllConsultants() {
    this.fb.getAllConsultants().subscribe(
      (result) => {
        this.consultants = result.map((item) => {
          return {
            $key: item.key,
            ...item.payload.val(),
            unread: 0,
          };
        });
        // Loop through consultants to check status
        this.consultants.forEach((consultant) => {
          if (
            consultant.role === "Supervisor" &&
            consultant.$key === this.chatService.activeGroupId
          ) {
            this.chatService.currentConsultantState = consultant.availability;
          }
        });
        // Sanitise out members with no uid (this is a bug coming from somewhere)
        this.consultants = this.consultants.filter(
          (consultant) => consultant.uid
        );
        // Sort by availability
        this.consultants.sort((x, y) => y.availability - x.availability);
        //console.log({ members: this.consultants });
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
  }

  timeSince(date: string) {
    if (date) {
      return moment(date)
        .add(120, "minutes") // Server time is 2hrs behind
        .toNow(true);
    }
  }

  constantUnreadMsgCountCheck() {
    const countFetch = () => {
      (CometChat as any).getUnreadMessageCountForAllGroups().then(
        (groupsCount) => {
          // Comes back as guid as key and value as unread count
          // Check incoming chats
          this.items.forEach(async (item, index) => {
            if (item.status !== ChatStatus.Closed) {
              this.items[index].unread = groupsCount[item.$key]
                ? groupsCount[item.$key]
                : 0;
            }
          });
          // Check supervisor chats
          this.consultants.forEach(async (item, index) => {
            if (item.availability && item.role === "Supervisor") {
              let userGroupUid = this.chatService
                .createDirectUserChatGroupUid(
                  item.$key,
                  this.chatService.userId
                )
                .toLowerCase();
              this.consultants[index].unread = groupsCount[userGroupUid]
                ? groupsCount[userGroupUid]
                : 0;
            }
          });
          this.consultants.sort((a, b) => {
            return b.unread - a.unread;
          });
        },
        (error) => {
          this.loggingService.updateLogBatch(JSON.stringify(error));
          console.log("Error in getting message count", error);
        }
      );
    };

    this.items.forEach(async (item) => {
      if (item.status !== ChatStatus.Closed) {
        await CometChat.getGroup(item.$key)
          .then(async (group: any) => {
            // Check if the user has already joined the group
            if (!group.hasJoined) {
              await this.joinGroupOnly(item.$key);
            }
          })
          .catch(async (error) => {
            this.loggingService.updateLogBatch(
              JSON.stringify(error),
              item.$key
            );
            if (error.code === "ERR_GROUP_NOT_JOINED") {
              await this.joinGroupOnly(item.$key);
            }
          });
      }
    });

    this.consultants!.forEach(async (item) => {
      if (item.availability && item.role === "Supervisor") {
        let userGroupUid = this.chatService.createDirectUserChatGroupUid(
          item.$key,
          this.chatService.userId
        );
        await CometChat.getGroup(userGroupUid)
          .then(async (group: any) => {
            // Check if the user has already joined the group
            if (!group.hasJoined) {
              await this.joinGroupOnly(userGroupUid);
            }
          })
          .catch(async (error) => {
            this.loggingService.updateLogBatch(
              JSON.stringify(error),
              item.$key
            );
            switch (error.code) {
              case "ERR_UID_NOT_FOUND":
                this.createUserGroup(userGroupUid);
                break;
              case "ERR_GROUP_NOT_JOINED":
                this.joinGroupOnly(userGroupUid);
                break;
            }
          });
      }
    });

    if (this.unreadTicker) this.unreadTicker.unsubscribe();
    this.unreadTicker = this.everyFiveSeconds.subscribe((seconds) => {
      countFetch();
    });
  }

  startTimer(expireTimestamp: number) {
    if (this.subscription) this.subscription.unsubscribe();
    this.subscription = this.everySecond.subscribe((seconds) => {
      const currentTime: number = moment().unix();
      this.remainingTime = expireTimestamp - currentTime;
      if (this.remainingTime <= 0) {
        this.minutes = 0;
        this.seconds = 0;
        this.TimerExpired.emit();
        this.timerProgress = 100;
      } else {
        this.timerProgress = 100 - (this.remainingTime / 900) * 100;
        this.minutes = Math.floor(this.remainingTime / 60);
        this.seconds = Math.floor(this.remainingTime - this.minutes * 60);
      }
    });
  }

  checkAutoTimerStart() {
    let groupTimerKey: string = `chat_${this.chatService.activeGroupId}`;
    let timerExpires: string = localStorage.getItem(groupTimerKey);

    if (timerExpires !== null) {
      this.startTimer(parseInt(timerExpires));
    }
  }

  configureChatTimer() {
    // Save timer start timestamp of current chat
    let groupTimerKey: string = `chat_${this.chatService.activeGroupId}`;
    let timerExpires: string = localStorage.getItem(groupTimerKey);
    if (timerExpires === null) {
      let expiredTimestamp: number = moment().add(15, "minutes").unix();
      // Creating timestamp
      localStorage.setItem(groupTimerKey, expiredTimestamp.toString());
      this.startTimer(expiredTimestamp);
    } else {
      // Timestamp already exists
      this.startTimer(parseInt(timerExpires));
    }
  }

  setConsultant(id) {
    this.fb.setConsultant(id, this.user.name, this.chatService.userId);
  }

  whatQueue(item) {
    this.setQueueQuestion = item;
  }

  chatForUser(chatType: string) {
    // Only match chat types for current role type
    let chatRoleMatch: boolean = true;
    if (this.user.role) {
      chatRoleMatch = this.user.role.toLowerCase() === chatType.toLowerCase();
      if (this.user.role === "Administrator") {
        chatRoleMatch =
          "admin" == chatType.toLowerCase() ||
          "anonymous_admin" == chatType.toLowerCase();
      }
      if (this.user.role === "Supervisor") {
        return true;
      }
    }
    return chatRoleMatch;
  }

  async getConsultant(key) {
    await this.fb.getConsultants(key).subscribe(
      (result) => {
        this.item = result.map((item) => {
          if (item.key === "name") {
            this.user.name = item.payload.val();
          }
          if (item.key === "email") {
            this.user.email = item.payload.val();
          }
          if (item.key === "uid") {
            this.user.uid = item.payload.val();
          }
          if (item.key === "role") {
            this.user.role = item.payload.val();
          }
          if (item.key === "availability") {
            //this.user.availability = item.payload.val();
            if (item.payload.val() === true) {
              this.user.availability = "available";
            } else {
              this.user.availability = "un-available";
            }
          }
          if (item.key === "queues") {
            this.user.queues = item.payload.val();
          }
          // return {
          //   $key: item.key,
          //   ...item.payload.val()
          // };
        });
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
  }

  getQuestions() {
    this.fb.getAdminQuestions().subscribe(
      (result) => {
        this.questionsAdmin = result.map((item) => {
          return {
            $key: item.key,
            ...item.payload.val(),
          };
        });
        //console.log(this.questionsAdmin);
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
    this.fb.getNurseQuestions().subscribe(
      (result) => {
        this.questionsNurse = result.map((item) => {
          return {
            $key: item.key,
            ...item.payload.val(),
          };
        });
        // console.log(this.questionsNurse);
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
  }
}
