import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { FirebaseServiceService } from "src/app/services/firebase-service.service";
import { CometChatService } from "src/app/services/comet-chat.service";
import {
  HttpHeaders,
  HttpClient,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { LoggingService } from "src/app/services/logging.service";

interface IUserObject {
  name: string;
  status: boolean;
  role: string;
  email: string;
  password: string;
  rsaId: string;
}

export enum UserRoles {
  Supervisor = "Supervisor",
  Administrator = "Administrator",
  Nurse = "Nurse",
}

@Component({
  selector: "app-queue",
  templateUrl: "./queue.component.html",
  styleUrls: ["./queue.component.scss"],
})
export class QueueComponent implements OnInit {
  modalRef: BsModalRef;
  items: any[];
  queues: any[];
  questionsAdmin: any[];
  questionsNurse: any[];
  selectedQueue: any;
  selectedQueueArray: any = {};
  selectedQueueArrayRemoved: any = {};
  selectedQueueEditMode: boolean = true;
  newQueue: any = { name: "", description: "", type: "", question: [] };
  selectedQuestion: any = { id: "", option: "" };
  selectedQuestionArray: any[] = [];
  newUserObject: IUserObject = {
    name: "",
    status: false,
    role: "",
    email: "",
    password: "",
    rsaId: "",
  };
  errorMsg: string = "";
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accept: "application/json",
    }),
  };
  private httpOptionsAuth = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accept: "application/json",
      appId: environment.cometChat.appId,
      apiKey: environment.cometChat.apiKey,
    }),
  };
  private cometchatURL = "https://medsoc-mw-api.appspot.com/cometchat";
  private editUserMode: string | null = null;

  // tslint:disable-next-line: max-line-length
  constructor(
    private modalService: BsModalService,
    private firebaseService: FirebaseServiceService,
    private chatService: CometChatService,
    private http: HttpClient,
    private loggingService: LoggingService
  ) {}

  ngOnInit() {
    this.getConsultant();
    this.getQueues();
    this.getQuestions();
  }

  private handleError(error: HttpErrorResponse) {
    this.loggingService.updateLogBatch(JSON.stringify(error));
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
      this.errorMsg = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      this.errorMsg = error.error.message;
      console.log(error, this.errorMsg);
      alert(this.errorMsg);
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }

  newUser() {
    // this.chatService.createCometChatUser(this.newUserObject);
    return this.http
      .post(this.cometchatURL, this.newUserObject, this.httpOptions)
      .pipe(catchError(this.handleError))
      .subscribe((res) => {
        console.log("User Added", res);
        this.modalRef.hide();
      });
  }

  removeUser(userId: string) {
    if (window.confirm("Are you sure you want to remove this user?")) {
      this.firebaseService.removeUser(userId);
    }
  }

  userEditMode(userId: string) {
    this.editUserMode
      ? (this.editUserMode = null)
      : (this.editUserMode = userId);
  }

  setUserRole(role: UserRoles, userId: string) {
    this.firebaseService.updateUserRole(role, userId);
    this.editUserMode = null;
  }

  removeQueue(id: string) {
    if (window.confirm("Are you sure you want to remove this queue?")) {
      this.firebaseService.removeQueueFromConsultant(id);
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  openModal2(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  openModal3(template: TemplateRef<any>, queue) {
    this.selectedQueueEditMode = true;
    this.selectedQueueArray = {};
    this.selectedQueue = queue;
    if (this.selectedQueue.members) {
      Object.keys(this.selectedQueue.members).forEach((member) => {
        this.selectedQueueArray[member] = this.selectedQueue.members[member];
      });
    }
    this.modalRef = this.modalService.show(template);
  }

  userQueues(userQueues: any) {
    let queues: string[] = [];
    if (userQueues.queues) {
      Object.keys(userQueues.queues).forEach((queue) => {
        queues.push(userQueues.queues[queue].name);
      });
    }
    return queues;
  }

  getConsultant() {
    this.firebaseService.getAllConsultants().subscribe(
      (result) => {
        this.items = result.map((item) => {
          return {
            $key: item.key,
            ...item.payload.val(),
          };
        });
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
  }

  generateArray(obj) {
    if (obj) {
      return Object.keys(obj).map((key) => {
        return {
          key: key,
          value: obj[key],
        };
      });
    } else {
      return "";
    }
  }

  addUserQueue(item, user, update: boolean = false) {
    if (!this.checkUserSelected(item)) {
      this.selectedQueueArray[item] = user;
    } else {
      if (update) {
        this.selectedQueueArrayRemoved[item] = user;
        delete this.selectedQueueArray[item];
      } else {
        delete this.selectedQueueArray[item];
      }
    }
  }

  addSelectedUsersQueue(editMode: boolean) {
    if (editMode) {
      Object.keys(this.selectedQueueArray).forEach((key) => {
        this.firebaseService.addUserQueue(
          key,
          this.selectedQueueArray[key],
          this.selectedQueue
        );
      });
      this.selectedQueueArray = {};
    } else {
      Object.keys(this.selectedQueueArrayRemoved).forEach((key) => {
        this.firebaseService.removeUserQueue(
          key,
          this.selectedQueueArrayRemoved[key],
          this.selectedQueue
        );
      });
      this.selectedQueueArrayRemoved = {};
    }
  }

  checkUserSelected(item: string): boolean {
    return this.selectedQueueArray[item] !== undefined;
  }

  checkListLength(queue: any) {
    return Object.keys(queue).length;
  }

  updateQueueStatus(template: TemplateRef<any>, queue) {
    this.selectedQueueEditMode = false;
    this.selectedQueueArray = {};
    this.selectedQueue = queue;
    Object.keys(this.selectedQueue.members).forEach((member) => {
      this.selectedQueueArray[member] = this.selectedQueue.members[member];
    });
    this.modalRef = this.modalService.show(template);
  }

  getQueues() {
    this.firebaseService.getQueues().subscribe(
      (result) => {
        this.queues = result.map((item) => {
          return {
            $key: item.key,
            ...item.payload.val(),
          };
        });
        // console.log({
        //   queues: this.queues,
        // });
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
  }

  setQueueStatus(queueId: string, status: boolean) {
    this.firebaseService.queueStatus(queueId, status);
  }

  addQuestionToQueueArray(question) {
    if (this.newQueue.question.some((q) => q.name === question.name)) {
      //console.log(this.newQueue.question);
    } else {
      this.newQueue.question.push(question);
      //console.log(this.newQueue.question);
    }
  }

  getQuestions() {
    this.firebaseService.getAdminQuestions().subscribe((result) => {
      this.questionsAdmin = result.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      //console.log(this.questionsAdmin);
    });
    this.firebaseService.getNurseQuestions().subscribe((result) => {
      this.questionsNurse = result.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
      //console.log(this.questionsNurse);
    });
  }

  createQueue(event: MouseEvent, template) {
    this.firebaseService.createQueue(this.newQueue);
    this.newQueue = [];
    event.preventDefault();
  }
}
