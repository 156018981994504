import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpClient,
} from "@angular/common/http";
import { Observable, timer } from "rxjs";

const LOGGING_API: string = "https://medsoc-mw-api.nw.r.appspot.com";
const LOG_ENDPOINT: string = `${LOGGING_API}/logs`;
const TIME: number = 5000;

interface ILogs {
  logs: ILog[];
}

interface ILog {
  agentID: string; //Logged in agent ID
  timestamp: string;
  log: string; //What is logged
  caseId?: string; //Chat case ID
}

@Injectable({
  providedIn: "root",
})
export class LoggingService {
  public loggingTimer: Observable<number> = timer(0, TIME);
  public userId: string;
  public logBatch: ILogs = { logs: [] };
  constructor(private http: HttpClient) {
    setTimeout(() => {
      this.loggingTimer.subscribe(() => {
        this.updateLog();
      });
    }, TIME);
  }

  updateLogBatch(logMsg: any, caseId: string = null) {
    let log: ILog = {
      agentID: this.userId,
      timestamp: Date.now().toString(),
      log: logMsg,
    };
    if(caseId) log.caseId = caseId;
    this.logBatch.logs.push(log);
  }

  updateLog() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "application/json",
      }),
    };
    const formattedBody: ILogs = this.logBatch;
    // Only send logs when there are logs to send
    if (this.logBatch.logs.length !== 0) {
      this.http
        .post(LOG_ENDPOINT, formattedBody, httpOptions)
        .subscribe((res) => {
          this.logBatch.logs = [];
        });
    }
  }
}
