import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  ChangeDetectorRef,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Subscription, Observable, timer } from "rxjs";
import * as moment from "moment";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ModalDirective } from "ngx-bootstrap/modal";
import { FirebaseServiceService } from "src/app/services/firebase-service.service";
import { Router, Params } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { LoggingService } from "src/app/services/logging.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  private subscription: Subscription;
  @Output() TimerExpired: EventEmitter<any> = new EventEmitter<any>();
  @Input() SearchDate: moment.Moment = moment();
  @Input() ElapsTime: number = 1;
  @Input() template: TemplateRef<any>;

  searchEndDate: moment.Moment;
  remainingTime: number;
  minutes: number;
  seconds: number;
  everySecond: Observable<number> = timer(0, 1000);
  modalRef: BsModalRef;

  config = {
    show: true,
    backdrop: true,
    ignoreBackdropClick: false,
  };
  errorMessage = "";
  email = "";
  password = "";
  currentUser: string;
  openChats = 0;
  assignedChats = 0;
  chats: any[];
  item: any[];
  user: any = {};
  avAgents = 0;
  unAvAgents = 0;
  selectedUser: any;

  // tslint:disable-next-line: max-line-length
  constructor(
    private ref: ChangeDetectorRef,
    private modalService: BsModalService,
    private fb: FirebaseServiceService,
    private router: Router,
    private afAuth: AngularFireAuth,
    private loggingService: LoggingService
  ) {
    this.searchEndDate = this.SearchDate.add(this.ElapsTime, "minutes");
  }

  @ViewChild("autoShownModal", { read: true }) autoShownModal: ModalDirective;
  isModalShown = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  openModal(template: TemplateRef<any>, user) {
    this.modalRef = this.modalService.show(template);
    this.selectedUser = user;
    console.log(user);
  }

  tryLogin(value) {
    this.fb.doLogin(value).then(
      (res) => {
        console.log(res);
        this.onHidden();
      },
      (err) => {
        console.log(err);
        this.errorMessage = err.message;
      }
    );
  }

  generateArray(obj) {
    if (obj) {
      return Object.keys(obj).map((key) => {
        return {
          key: key,
          value: obj[key],
        };
      });
    } else {
      return "";
    }
  }

  ngOnInit() {
    this.afAuth.authState.subscribe(
      (data) => {
        if (data && data.email && data.uid) {
          // console.log(data);
          this.currentUser = data.uid;
          this.onHidden();
          this.getChats();
          this.getConsultant();
        } else {
          this.showModal();
        }
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
  }

  getConsultant() {
    this.fb.getAllConsultants().subscribe(
      (result) => {
        this.avAgents = 0;
        this.unAvAgents = 0;
        this.item = result.map((item) => {
          if (item.payload.val().availability === true) {
            this.avAgents += 1;
          } else {
            this.unAvAgents += 1;
          }
          return {
            $key: item.key,
            ...item.payload.val(),
          };
        });
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
  }

  getChats() {
    this.fb.getAllChats().subscribe(
      (result) => {
        this.openChats = 0;
        this.assignedChats = 0;
        this.chats = result.map((item) => {
          if (item.payload.val().status === "open") {
            this.openChats += 1;
          }
          if (item.payload.val().status === "assigned") {
            this.assignedChats += 1;
          }
        });
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
  }
}
