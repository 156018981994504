import { Injectable } from "@angular/core";
import { CometChat } from "@cometchat-pro/chat";
import { environment } from "src/environments/environment";
import { Observable, ReplaySubject, Subject, from, throwError } from "rxjs";
import { filter, flatMap, tap } from "rxjs/operators";
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import moment from "moment";
import { catchError, retry } from "rxjs/operators";
import { LoggingService } from "./logging.service";

export enum MessageStatus {
  Sent = 0,
  Delivered = 1,
  Read = 2,
}

export interface IChatMessage {
  name: string;
  uid: string;
  id?: string;
  message: string;
  status?: MessageStatus;
  arrived?: boolean;
  type?: string;
  attachments?: any;
  deleted?: boolean;
  receiverId?: string;
  time: string;
  isBotMessage?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class CometChatService {
  private initialized: Subject<boolean> = new ReplaySubject();
  private signedIn$: Subject<string> = new ReplaySubject();
  private _signedIn: boolean = false;
  messages$: Subject<any> = new ReplaySubject();
  userId: string;
  sendingMsg: boolean = false;
  activeChat: any = null;
  activeGroupId: string | null;
  activeUserChatId: string | null;
  activeChatStillActive: boolean | null = null;
  activeConsultantId: string | null = null;
  activeChatStatus: string | null = null;
  currentConsultantState: boolean | null = null;

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService
  ) {
    let appSetting = new CometChat.AppSettingsBuilder()
                        .subscribePresenceForAllUsers()
                        .setRegion(environment.cometChat.region)
                        .autoEstablishSocketConnection(true)
                        .build();
    CometChat.init(environment.cometChat.appId, appSetting).then(
      (_) => {
        console.log("Comet Chat initialized.");
        this.initialized.next(true);
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
        console.log("Initialization error: " + error);
      }
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this.loggingService.updateLogBatch(JSON.stringify(error));
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      this.loggingService.updateLogBatch(JSON.stringify(error));
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError({
      error: error.error.message,
    });
  }

  createGroup(GUID, groupName) {
    const group = new CometChat.Group(
      GUID,
      groupName,
      CometChat.GROUP_TYPE.PUBLIC,
      ""
    );

    CometChat.createGroup(group).then(
      (group) => {
        this.loggingService.updateLogBatch(JSON.stringify(group));
        console.log("Group created successfully:", group);
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
        console.log("Group creation failed with exception:", error);
      }
    );
  }

  addUserToGroup(uid, GUID) {
    const membersList = [
      new CometChat.GroupMember(uid, CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT),
    ];
    CometChat.addMembersToGroup(GUID, membersList, []).catch((error) => {
      this.loggingService.updateLogBatch(JSON.stringify(error));
      console.log("Something went wrong", error);
    });
  }

  removeUserFromGroup(uid, GUID) {
    console.log(GUID, uid);
    CometChat.kickGroupMember(GUID, uid).then(
      (response) => {
        this.loggingService.updateLogBatch(JSON.stringify(response));
        console.log("response", response);
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
        console.log("Something went wrong", error);
      }
    );
  }

  leaveGroup(groupId: string, callback: Function = null) {
    CometChat.leaveGroup(groupId).then(
      (hasLeft) => {
        this.loggingService.updateLogBatch(
          JSON.stringify("Group left successfully:" + hasLeft)
        );
        console.log("Group left successfully:", hasLeft);
        if (callback) {
          callback();
        }
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
        console.log("Group leaving failed with exception:", error);
      }
    );
  }

  login(uid: string): Observable<any> {
    uid = uid.toLowerCase();
    return this.initialized.pipe(
      filter((v) => v),
      flatMap(() => {
        return from(CometChat.login(uid, environment.cometChat.apiKey)).pipe(
          tap((user: any) => {
            this.signedIn$.next(uid);
            this._signedIn = true;
          }),
          catchError(err => throwError(err))
        );
      })
    );
  }

  getPreviousMessages(groupUid: string) {
    this.activeGroupId = groupUid;
    const messageRequest = new CometChat.MessagesRequestBuilder()
      .setGUID(groupUid)
      .setLimit(100)
      .build();
    return messageRequest.fetchPrevious();
  }

  getPreviousUserMessages(uid: string) {
    const messageRequest = new CometChat.MessagesRequestBuilder()
      .setUID(uid)
      .setLimit(100)
      .build();
    return messageRequest.fetchPrevious();
  }

  createDirectUserChatGroupUid(person1Id: string, person2Id: string) {
    let longUid: string = person1Id + person2Id;
    let arr = longUid.split("");
    let sorted = arr.sort();
    return sorted.join("");
  }

  messageListener(callback: any, typingCallback: any, messageStatus: any) {
    CometChat.addMessageListener(
      this.userId,
      new CometChat.MessageListener({
        onMessagesDelivered: (messageReceipt) => {
          messageStatus(messageReceipt, MessageStatus.Delivered);
          this.loggingService.updateLogBatch({
            message: messageReceipt,
            messageStatus: MessageStatus.Delivered,
          });
        },
        onMessagesRead: (messageReceipt) => {
          messageStatus(messageReceipt, MessageStatus.Read);
          this.loggingService.updateLogBatch({
            message: messageReceipt,
            messageStatus: MessageStatus.Read,
          });
        },
        onTextMessageReceived: (textMessage) => {
          this.loggingService.updateLogBatch(JSON.stringify(textMessage));
          callback(textMessage);
        },
        onMediaMessageReceived: (textMessage) => {
          this.loggingService.updateLogBatch(JSON.stringify(textMessage));
          callback(textMessage);
        },
        onCustomMessageReceived: (textMessage) => {
          this.loggingService.updateLogBatch(JSON.stringify(textMessage));
          callback(textMessage);
        },
        onTypingStarted: (event) => {
          this.loggingService.updateLogBatch(JSON.stringify(event));
          typingCallback(true, event);
        },
        onTypingEnded: (event) => {
          this.loggingService.updateLogBatch(JSON.stringify(event));
          typingCallback(false, event);
        },
      })
    );
  }

  removeMessageListener() {
    CometChat.removeMessageListener(this.userId);
  }

  clearChats() {
    this.removeMessageListener();
    this.activeGroupId = null;
    this.activeChatStillActive = null;
    this.currentConsultantState = null;
    this.messages$.unsubscribe();
    this.messages$ = new ReplaySubject();
  }

  createCometChatUser(user) {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     "Content-Type": "application/json",
    //     accept: "application/json"
    //     // 'appId': environment.cometChat.appId,
    //     // 'apiKey': environment.cometChat.apiKey
    //   })
    // };
    // // const URL = 'https://medsoc-mw-api.appspot.com/cometchat';
    // const URL = "https://medsoc-mw-api.appspot.com/cometchat";
    // return this.http.post(URL, user, httpOptions).subscribe(res => {
    //   console.log(res);
    // });

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "application/json",
        // 'appId': environment.cometChat.appId,
        // 'apiKey': environment.cometChat.apiKey
      }),
    };
    // const URL = 'https://medsoc-mw-api.appspot.com/cometchat';
    const URL = "https://medsoc-mw-api.appspot.com/cometchat";
    return this.http
      .post(URL, user, httpOptions)
      .pipe(catchError(this.handleError))
      .subscribe((res) => {
        console.log("User Added");
      });
  }

  getSignedIn(): Observable<string> {
    return this.signedIn$;
  }

  isSignedIn(): boolean {
    return this._signedIn;
  }

  sendMessage(
    groupId: string,
    content: string,
    callback: Function = null
  ): void {
    this.sendingMsg = true;

    let message = new CometChat.TextMessage(
      groupId,
      content,
      CometChat.RECEIVER_TYPE.GROUP
    );
    CometChat.sendMessage(message)
      .then((event) => {
        //console.log(event);
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      })
      .finally(() => {
        this.sendingMsg = false;
      });
  }

  sendMediaMessage(
    groupId: string,
    file: any,
    callback: Function = null
  ): void {
    this.sendingMsg = true;
    let mediaMessage = new CometChat.MediaMessage(
      groupId,
      file,
      CometChat.MESSAGE_TYPE.FILE,
      CometChat.RECEIVER_TYPE.GROUP
    );
    CometChat.sendMediaMessage(mediaMessage)
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      })
      .finally(() => {
        this.sendingMsg = false;
      });
  }

  deleteMessage(msgId: any) {
    (CometChat as any).deleteMessage(msgId).then(
      (message) => {
        this.loggingService.updateLogBatch(JSON.stringify(message));
        console.log("Message deleted", message);
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
        console.log("Message delete failed with error:", error);
      }
    );
  }

  getMessages(): Observable<any> {
    return this.messages$;
  }

  setMessage(message: any): IChatMessage {
    let bot = false;

    if (!message.data.metadata) {
      bot = false;
    } else {
      if (!message.data.metadata.isBotMessage) {
        bot = false;
      } else {
        bot = true;
      }
    }

    return {
      name: message.sender.name,
      uid: message.sender.uid,
      id: message.id,
      message: message.text,
      status: message.status,
      arrived: this.userId !== message.sender.uid,
      type: message.data.entities.sender.entityType,
      attachments: message.data.attachments ? message.data.attachments : null,
      deleted: message.deletedAt ? true : false,
      receiverId: message.receiver,
      isBotMessage: bot,
      time: moment.unix(message.sentAt).format("ddd, HH:mm"),
    };
  }
}
