// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDYxKOV8UBJ39Kvdq3J8ayt4VAvmel0P4g",
    authDomain: "med-soc-app.firebaseapp.com",
    databaseURL: "https://med-soc-app.firebaseio.com",
    //databaseURL: "med-soc-app-debug.firebaseio.com",
    projectId: "med-soc-app",
    storageBucket: "med-soc-app.appspot.com",
    messagingSenderId: "416986938179",
    appId: "1:416986938179:web:b022bd7bb9e73129",
    functionsURL: "https://us-central1-med-soc-app.cloudfunctions.net"
  },
  cometChat: {
    apiKey: "755313ecd901f1f3f96d9671642c7a23deecbe30",
    appId: "201526d7c7afe76a",
    groupId: "034e3d7f4070af7391e0e661b859e60d",
    region: "eu"
  },
  url: "http://localhost:4200/chat?groupId="
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
