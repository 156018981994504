import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { FirebaseServiceService } from "src/app/services/firebase-service.service";
import { CometChatService } from "./services/comet-chat.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { ModalDirective } from "ngx-bootstrap/modal";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { setTheme } from "ngx-bootstrap/utils";
import { GlobalService } from "./services/global.service";
import { LoggingService } from "./services/logging.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "Medsoc Desktop Chat";
  errorMessage = "";
  email = "";
  password = "";
  currentUser: string;
  singleModel = "1";
  user: any = {};
  item: any[];
  radioModel = "Middle";
  newUserObject = {
    name: "",
    status: "",
    role: "",
    email: "",
    password: "",
    rsaId: "",
  };
  openChats = 0;
  assignedChats = 0;
  chats: any[];
  supervisorMode: boolean = false;
  // tslint:disable-next-line: max-line-length
  @HostListener("window:beforeunload", ["$event"])
  unLoad(event) {
    if (!sessionStorage.getItem("sessionActive")) this.logout();
  }

  constructor(
    private firebaseService: FirebaseServiceService,
    private fb: FirebaseServiceService,
    private afAuth: AngularFireAuth,
    private globalService: GlobalService,
    private loggingService: LoggingService
  ) {
    setTheme("bs4");
    //
    window.onbeforeunload = async (e) => {
      await this.setAvailability();
    };
    this.globalService.connectionStatus();
  }

  @ViewChild("autoShownModal", { read: true }) autoShownModal: ModalDirective;
  isModalShown = false;
  isResetModal = false;

  showModal(): void {
    this.isModalShown = true;
  }

  resetModalToggle(): void {
    this.isResetModal = !this.isResetModal;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  tryLogin(value) {
    this.fb.doLogin(value).then(
      (res) => {
        console.log(res);
        this.onHidden();
      },
      (err) => {
        this.loggingService.updateLogBatch(JSON.stringify(err));
        this.errorMessage = err.message;
      }
    );
  }

  ngOnInit() {
    this.afAuth.authState.subscribe((data) => {
      if (data && data.email && data.uid) {
        // console.log(data);
        this.currentUser = data.uid;
        sessionStorage.setItem("sessionActive", "true");
        this.getConsultant(data.uid);
        this.onHidden();
      } else {
        this.showModal();
      }
    });
  }

  async setAvailability() {
    if (this.user.availability === "available") {
      await this.firebaseService.updateUserAvailability(
        this.currentUser,
        false
      );
    } else {
      await this.firebaseService.updateUserAvailability(this.currentUser, true);
    }
  }

  getConsultant(key) {
    this.firebaseService.getConsultants(key).subscribe((result) => {
      this.item = result.map((item) => {
        if (item.key === "assist") {
          this.user.assist = item.payload.val();
        }
        if (item.key === "name") {
          this.user.name = item.payload.val();
          this.fb.activeUserName = this.user.name;
        }
        if (item.key === "email") {
          this.user.email = item.payload.val();
        }
        if (item.key === "uid") {
          this.user.uid = item.payload.val();
          this.loggingService.userId = this.user.uid;
        }
        if (item.key === "role") {
          this.user.role = item.payload.val();
          if (this.user.role === "Supervisor") this.supervisorMode = true;
        }
        if (item.key === "availability") {
          //this.user.availability = item.payload.val();
          if (item.payload.val() === true) {
            this.user.availability = "available";
          } else {
            this.user.availability = "un-available";
          }
        }
        if (item.key === "queues") {
          this.user.queues = item.payload.val();
        }
        // return {
        //   $key: item.key,
        //   ...item.payload.val()
        // };
      });
      setTimeout(() => {
        this.globalService.user = this.user;
      }, 0);
      this.getChats();
    });
  }

  getChats() {
    this.firebaseService.getAllChats().subscribe((result) => {
      this.openChats = 0;
      this.chats = result.map((item) => {
        if (item.payload.val().status === "open") {
          this.openChats += 1;
        }
        if (item.payload.val().status === "assigned") {
          this.assignedChats += 1;
        }
      });
    });
  }

  logout() {
    this.loggingService.updateLog();
    this.firebaseService.updateUserAvailability(this.currentUser, false);
    this.firebaseService.doLogout();
    sessionStorage.clear();
  }

  reset(email) {
    if (this.validateEmail(email)) {
      this.firebaseService.resetPassword(email);
    } else {
      alert("Please enter a valid email address.");
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
