import { Injectable } from "@angular/core";
import { CometChatService } from "./comet-chat.service";
import { FirebaseServiceService } from "./firebase-service.service";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  public online: boolean = true;
  public failed: boolean = false;
  public chatDisconnected: boolean = false;
  public connectionLostChatsIds: string[] = [];
  public user: any;
  constructor(
    private chatService: CometChatService,
    private firebaseService: FirebaseServiceService
  ) {}

  // Check every second for connection status
  connectionStatus() {
    setInterval(() => {
      this.online = window.navigator.onLine;
      if (!window.navigator.onLine) {
        this.failed = true;
      }
    }, 1000);
  }

  updateDisconnectedIds(chatId: string) {
    let alreadyExists: boolean =
      this.connectionLostChatsIds.filter((id) => chatId === id).length !== 0;
    if (!alreadyExists) {
      this.connectionLostChatsIds.push(chatId);
    }
  }

  checkIfDisconnected() {
    if (this.chatService.activeGroupId) {
      return (
        this.connectionLostChatsIds.filter(
          (id) => this.chatService.activeGroupId === id
        ).length !== 0
      );
    }
    return false;
  }

  refresh() {
    this.firebaseService.setConnectionLost(this.chatService.activeGroupId);
  }

  refreshClient() {
    window.location.reload();
  }

  getUserNotAvailable() {
    return this.user.availability !== "available";
  }
}
