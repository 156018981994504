import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ChatsComponent } from "./components/chats/chats.component";
import { StatsComponent } from "./components/stats/stats.component";
import { ChatViewComponent } from "./components/chat-view/chat-view.component";
import { QueueComponent } from './components/queue/queue.component';

const routes: Routes = [
  { path: "", component: DashboardComponent, pathMatch: "full" },
  { path: "chats", component: ChatsComponent, pathMatch: "full" },
  { path: "chat", component: ChatViewComponent, pathMatch: "full" },
  { path: "stats", component: StatsComponent, pathMatch: "full" },
  { path: "settings", component: QueueComponent, pathMatch: "full"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
