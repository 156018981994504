import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase, AngularFireList } from "@angular/fire/database";
import * as firebase from "firebase/app";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { CometChatService, IChatMessage } from "./comet-chat.service";
import { environment } from "../../environments/environment";
import { UserRoles } from "../components/queue/queue.component";
import { LoggingService } from "./logging.service";

@Injectable({
  providedIn: "root",
})
export class FirebaseServiceService {
  constructor(
    private db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    private http: HttpClient,
    private cometchat: CometChatService,
    private loggingService: LoggingService
  ) {}

  itemsAf: AngularFireList<any>;
  chat: AngularFireList<any>;
  chatInternal: AngularFireList<any>;
  activeInternalChat: string | null = null;
  activeUserName: string = "";
  profiles: AngularFireList<any>;
  queues: AngularFireList<any>;
  consultant: AngularFireList<any>;
  consultants: AngularFireList<any>;
  adminQuestions: AngularFireList<any>;
  nurseQuestions: AngularFireList<any>;

  getChats() {
    this.itemsAf = this.db.list("/chat_bucket", (ref) =>
      // ref.orderByChild('status').equalTo('open')
      ref.orderByChild("activeChat").equalTo(true)
    );
    return this.itemsAf.snapshotChanges();
  }

  getChatsStats(startDate = null, endDate = null) {
    this.itemsAf = this.db.list("/chat_bucket", (ref) => {
      if (startDate && endDate) {
        return ref
          .orderByChild("itemFormattedDate")
          .startAt(startDate)
          .endAt(endDate);
      } else {
        return ref.orderByChild("itemFormattedDate");
      }
    });
    return this.itemsAf.snapshotChanges();
  }

  getAllChats() {
    this.itemsAf = this.db.list("/chat_bucket/");
    return this.itemsAf.snapshotChanges();
  }

  getConsultants(item) {
    this.profiles = this.db.list("/consultants/" + item);
    return this.profiles.snapshotChanges();
  }

  getConsultant(item) {
    this.consultant = this.db.list("/consultants/" + item);
    return this.profiles.snapshotChanges();
  }

  getAllConsultants() {
    this.profiles = this.db.list("/consultants/");
    return this.profiles.snapshotChanges();
  }

  getInternalChat(key: string) {
    this.chatInternal = this.db.list("/chat_internal/" + key);
    return this.chatInternal.snapshotChanges();
  }

  removeUser(userId: string) {
    let newDisabledKey = this.db.database
      .ref("/consultants/" + userId)
      .child("disabled")
      .push().key;
    var state = {};
    state["/disabled/"] = true;
    this.db.database.ref("/consultants/" + userId).update(state);
    // This used to remove the authenticated user as well. Use with Caution!!!
    // this.http
    //   .get(environment.firebase.functionsURL + "/removeUser?uid=" + userId)
    //   .subscribe((response: any) => {
    //     if (response === null) {
    //       //Success
    //     }
    //   });
  }

  updateUserRole(role: UserRoles, userId: string) {
    this.db.database.ref("/consultants/" + userId).update({ role: role });
  }

  updateUserAssist(chat: any, userId: string) {
    let chatCopy = { ...chat };
    delete chatCopy.$key;
    this.db.database
      .ref("/consultants/" + userId + "/assist")
      .set({ ...chatCopy });
  }

  removeUserAssist(userId: string) {
    this.db.database.ref("/consultants/" + userId + "/assist").remove();
  }

  removeQueueFromConsultant(queueId: string) {
    this.db.database
      .ref("/chat_queue/" + queueId)
      .once("value")
      .then((snapshot: any) => {
        let members: string[] = Object.keys(snapshot.val().members);
        members.forEach((memberId) => {
          this.db.database
            .ref("/consultants/" + memberId + "/queues/" + queueId)
            .remove();
        });
        this.db.database.ref("/chat_queue/" + queueId).remove();
      })
      .catch((error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      });
  }

  createCometChatUser(key) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accept: "application/json",
        // 'appId': environment.cometChat.appId,
        // 'apiKey': environment.cometChat.apiKey
      }),
    };
    const body = {
      groupId: key,
    };
    // const URL = 'https://medsoc-mw-api.appspot.com/cometchat';
    const URL = "http://localhost:8080/cometchat-queue";
    this.http.post(URL, body, httpOptions).subscribe(
      (res) => {
        console.log(res);
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
  }

  updateUserAvailability(item, userAvailability: boolean) {
    this.afAuth.authState.subscribe(
      (data) => {
        if (data && data.email && data.uid) {
          this.profiles = this.db.list("/consultants/");
          this.profiles.update(item, { availability: userAvailability });
        } else {
          // alert('not logged in');
        }
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
  }

  updateUserDetails(item) {
    this.afAuth.authState.subscribe(
      (data) => {
        if (data && data.email && data.uid) {
          this.profiles = this.db.list("/consultants/");
          this.profiles.update(item.uid, item);
        } else {
          //alert('not logged in');
        }
      },
      (error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      }
    );
  }

  addUserQueue(item, user, queueId) {
    this.profiles = this.db.list("/consultants/" + item + "/queues/");
    this.profiles.set(queueId["$key"], { status: true, name: queueId.name });

    this.queues = this.db.list("/chat_queue/" + queueId["$key"] + "/members");
    this.queues.set(item, { name: user.name }).then((result) => {
      this.cometchat.addUserToGroup(item, queueId["$key"]);
    });
  }

  removeUserQueue(item, user, queueId) {
    console.log(queueId, item);
    this.profiles = this.db.list("/consultants/" + item + "/queues/");
    this.profiles.remove(queueId["$key"]);

    this.queues = this.db.list("/chat_queue/" + queueId["$key"] + "/members");
    this.queues
      .remove(item)
      .then((result) => {
        this.cometchat.removeUserFromGroup(item, queueId["$key"]);
      })
      .catch((error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      });
  }

  queueStatus(item, newStatus) {
    this.queues = this.db.list("/chat_queue/");
    this.queues.update(item, { status: newStatus });
  }

  getAdminQuestions() {
    this.adminQuestions = this.db.list("/admin_chat_pre_form/");
    return this.adminQuestions.snapshotChanges();
  }

  getNurseQuestions() {
    this.nurseQuestions = this.db.list("/nurse_chat_pre_form/");
    return this.nurseQuestions.snapshotChanges();
  }

  closeAllChats() {
    // For closing a backlog of chats
    this.db.database
      .ref("/chat_bucket/")
      .once("value")
      .then((snapshot) => {
        let keys = Object.keys(snapshot.val());
        keys.forEach((key) => {
          this.db.list("/chat_bucket/").update(key, { activeChat: false });
        });
      })
      .catch((error) => {
        this.loggingService.updateLogBatch(JSON.stringify(error));
      });
  }

  closeChats(item) {
    this.chat = this.db.list("/chat_bucket/");
    this.chat.update(item, { status: "closed" });
  }

  getInternalNewChatKey() {
    return this.db.database.ref().child("chat_internal").push().key;
  }

  pushInternalChatMessage(message: IChatMessage, key: string) {
    if (key !== undefined) {
      let messages: IChatMessage[] = [];
      this.db.database
        .ref("/chat_internal/" + key)
        .once("value")
        .then((snapshot) => {
          messages = snapshot.val();
          // If chat already exists add new message
          if (messages !== null) {
            messages.push(message);
          } else {
            messages = [{ ...message }];
          }
          this.db.database.ref("/chat_internal/").update({
            [key]: [...messages],
          });
        })
        .catch((error) => {
          this.loggingService.updateLogBatch(JSON.stringify(error));
        });
    }
  }

  addInternalChatLink(groupQueue: boolean, item, chatId: string): string {
    let itemCopy = { ...item };
    let chatBucketKey: string = itemCopy.$key;
    delete itemCopy.$key;
    let dbRefName: string = "/chat_bucket/";
    if (groupQueue) dbRefName = "/chat_queue/";
    this.db.database.ref().update({
      [dbRefName + chatBucketKey]: { ...itemCopy, internalChat: chatId },
    });
    return chatId;
  }

  assignSupervisor(item: any, supervisorId: string) {
    let itemCopy = { ...item };
    let dbRefName: string = "/chat_bucket/";
    let chatBucketKey: string = item.$key;
    delete itemCopy.$key;
    this.db.database.ref().update({
      [dbRefName + chatBucketKey]: {
        ...itemCopy,
        internalSupervisor: supervisorId,
      },
    });
  }

  chatHasAttachments(item, val: boolean) {
    this.chat = this.db.list("/chat_bucket/");
    this.chat.update(item, { hasAttachments: val });
  }

  stopBot(item) {
    this.chat = this.db.list("/chat_bucket/");
    this.chat.update(item, { botInProgress: false });
  }
  setConsultant(item, val: string, agentId: string) {
    // this.chat = this.db.list('/chat_bucket/');
    // this.chat.update(item, {consultant: val, status: 'assigned'});
    // this.db.database.ref().update({
    //   ['/chat_bucket/' + item]: {consultant: val, status: 'assigned'}
    // });
    this.db.database.ref("/chat_bucket/" + item + "/consultant").set(val);
    this.db.database.ref("/chat_bucket/" + item + "/consultantID").set(agentId);
    this.db.database.ref("/chat_bucket/" + item + "/assigned").set(true);

    // return item;
  }

  unsetConsultant(item) {
    this.db.database.ref("/chat_bucket/" + item + "/consultant").remove();
    this.db.database.ref("/chat_bucket/" + item + "/consultantID").remove();
    this.db.database.ref("/chat_bucket/" + item + "/assigned").set(false);
  }

  setCallbackChat(bucketId: string, value: boolean) {
    this.db.database.ref("/chat_bucket/" + bucketId + "/callBack").set(value);
    if (value === true) {
      this.cometchat.activeChatStillActive = false;
      this.cometchat.currentConsultantState = null;
    }
  }

  setEmergencyChat(bucketId: string) {
    this.db.database.ref("/chat_bucket/" + bucketId + "/emergency").set(false);
    this.db.database.ref("/chat_bucket/" + bucketId + "/activeChat").set(false);
  }

  setConnectionLost(bucketId: string) {
    this.db.database
      .ref("/chat_bucket/" + bucketId + "/connectionLost")
      .set(false);
  }

  joinChat(item) {
    // this.chat = this.db.list('/chat_bucket/');
    // this.chat.update(item, {consultant: val, status: 'assigned'});
    // this.db.database.ref().update({
    //   ['/chat_bucket/' + item]: {botInProgress: false}
    // });
    this.db.database.ref("/chat_bucket/" + item + "/botInProgress").set(false);
  }

  createQueue(item) {
    item = {
      ...item,
      members: 0,
    };
    this.queues = this.db.list("/chat_queue");
    this.queues.push(item).then((result) => {
      this.cometchat.createGroup(result.key, item.name);
    });
  }

  getQueues() {
    this.queues = this.db.list("/chat_queue");
    return this.queues.snapshotChanges();
  }

  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => {
            resolve(res);
          },
          (err) => reject(err)
        )
        .catch((error) => {
          this.loggingService.updateLogBatch(JSON.stringify(error));
        });
    });
  }

  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => {
            console.log(res);
            resolve(res);
          },
          (err) => reject(err)
        )
        .catch((error) => {
          this.loggingService.updateLogBatch(JSON.stringify(error));
        });
    });
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        this.afAuth.auth.signOut();
        resolve();
      } else {
        reject();
      }
    });
  }

  resetPassword(email) {
    this.afAuth.auth
      .sendPasswordResetEmail(email)
      .then((res) => {
        alert("please check you emails for a reset link");
        return {
          status: 200,
          message: "please check you emails for a reset link",
        };
      })
      .catch(function (error) {
        this.loggingService.updateLogBatch(JSON.stringify(error));
        this.setState({ error: error.message });
      });
  }
}
