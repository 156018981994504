import { Component, OnInit, Input } from "@angular/core";

interface IHeading {
  label: string;
  description: string | null | undefined;
}

@Component({
  selector: "stats-table",
  templateUrl: "./stats-table.component.html",
  styleUrls: ["./stats-table.component.scss"],
})
export class StatsTableComponent implements OnInit {
  @Input() headings: IHeading[];
  @Input() data: string[][];
  @Input() loading: boolean;
  @Input() caption: string;
  constructor() {}

  ngOnInit() {}
}
