import { Component, OnInit } from "@angular/core";
import { FirebaseServiceService } from "src/app/services/firebase-service.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { IChatsItem } from "../chats/chats.component";
import * as moment from "moment";
import { LoggingService } from "src/app/services/logging.service";

interface IAgentFilter {
  label: string;
  id: string | null;
}

const allAgentsDefault = {
  label: "All Agents",
  id: null,
};

@Component({
  selector: "app-stats",
  templateUrl: "./stats.component.html",
  styleUrls: ["./stats.component.scss"],
})
export class StatsComponent implements OnInit {
  loading: boolean = true;
  chats: IChatsItem[] = [];
  dateRange: Date[] | null = [
    new Date(new Date().setDate(new Date().getDate() - 30)),
    new Date(),
  ];
  agentFilter: IAgentFilter[] = [allAgentsDefault];
  agentSelected: IAgentFilter = this.agentFilter[0];
  totalChats: number = 0;
  filterModel: string = "last_30";
  appReports = {
    headings: [
      {
        label: "Chat Received",
      },
      {
        label: "Chats Completed",
      },
      {
        label: "Chats Missed",
      },
      {
        label: "Avg Response",
        description: "Time Between Replies",
      },
      {
        label: "Avg Handling",
        description: "Time Per Case",
      },
    ],
    data: [],
  };
  optionsMedicalReports = {
    headings: [
      {
        label: "Option",
      },
      {
        label: "Amount",
      },
    ],
    data: [],
  };
  optionsMemberReports = {
    headings: [
      {
        label: "Option",
      },
      {
        label: "Amount",
      },
    ],
    data: [],
  };
  preferredLangReports = {
    headings: [
      {
        label: "#",
      },
      {
        label: "Language",
      },
      {
        label: "Count",
      },
    ],
    data: [],
  };
  constructor(
    private fb: FirebaseServiceService,
    private loggingService: LoggingService
  ) {}

  ngOnInit() {
    this.addAllConsultants();
  }

  addAllConsultants() {
    this.fb.getAllConsultants().subscribe((results) => {
      this.agentFilter = results.map((item) => {
        return {
          id: item.key,
          label: item.payload.val().name,
        };
      });
      this.agentFilter.unshift(allAgentsDefault);
    });
  }

  setAgent(id: string | null) {
    if (id) {
      this.agentSelected = this.agentFilter.filter(
        (agent) => agent.id === id
      )[0];
    } else {
      this.agentSelected = this.agentFilter[0];
    }
    this.getQueues();
  }

  dateRangeUpdate(event) {
    this.dateRange = event;
    this.getQueues();
  }

  updateFilters(filterModel) {
    switch (filterModel) {
      case "all_time":
        this.dateRange = null;
        break;
      case "this_year":
        this.dateRange = [new Date(new Date().getFullYear(), 0, 1), new Date()];
        break;
      default:
        this.dateRange = [
          new Date(new Date().setDate(new Date().getDate() - 30)),
          new Date(),
        ];
        break;
    }
  }

  getQueues() {
    this.loading = true;
    this.fb
      .getChatsStats(
        this.dateRange ? moment(this.dateRange[0]).format("YYYY-MM-DD") : null,
        this.dateRange ? moment(this.dateRange[1]).format("YYYY-MM-DD") : null
      )
      .subscribe(
        (result) => {
          this.chats = result.map((item) => {
            return {
              $key: item.key,
              ...item.payload.val(),
            };
          });
          if (this.agentSelected.id) {
            this.chats = this.chats.filter(
              (chat) => chat.consultantID === this.agentSelected.id
            );
          }
          this.totalChats = this.chats.length;
          this.buildStatsData();
        },
        (error) => {
          this.loggingService.updateLogBatch(JSON.stringify(error));
        }
      );
  }

  buildStatsData() {
    this.loading = false;
    this.appReports.data = [this.appReportsData()];
    this.optionsMedicalReports.data = this.optionsMedicalReportsData();
    this.optionsMemberReports.data = this.optionsMemberReportsData();
    this.preferredLangReports.data = this.preferredLangReportsData();
  }

  appReportsData() {
    let chatsReceived: string = this.chats.length.toString();
    let chatsCompleted: string = this.chats
      .filter((chat) => chat.status === "closed" && !chat.activeChat)
      .length.toString();
    let chatsMissed: string = this.chats
      .filter((chat) => !chat.consultantID)
      .length.toString();
    let avgResponse: string = "00:00";
    let avgHandling: string = "00:00";

    return [
      chatsReceived,
      chatsCompleted + ` (${this.toPercentage(chatsCompleted)}%)`,
      chatsMissed + ` (${this.toPercentage(chatsMissed)}%)`,
      avgResponse,
      avgHandling,
    ];
  }

  optionsMedicalReportsData() {
    let optionComplaint: string = "I have a new medical complaint";
    let optionEmergency: string = "I have an Emergency";
    let optionConsultation: string = "I need a consultation";
    let optionOther: string = "Other";
    let amountComplaint: string = this.chats
      .filter((chat) => chat.firstTopic === optionComplaint)
      .length.toString();
    let amountEmergency: string = this.chats
      .filter((chat) => chat.firstTopic === optionEmergency)
      .length.toString();
    let amountConsultation: string = this.chats
      .filter((chat) => chat.firstTopic === optionConsultation)
      .length.toString();
    let amountOther: string = this.chats
      .filter((chat) => chat.firstTopic === optionOther)
      .length.toString();
    return [
      [
        optionComplaint,
        amountComplaint + ` (${this.toPercentage(amountComplaint)}%)`,
      ],
      [
        optionEmergency,
        amountEmergency + ` (${this.toPercentage(amountEmergency)}%)`,
      ],
      [
        optionConsultation,
        amountConsultation + ` (${this.toPercentage(amountConsultation)}%)`,
      ],
      [optionOther, amountOther + ` (${this.toPercentage(amountOther)}%)`],
    ];
  }

  optionsMemberReportsData() {
    let optionPayment: string = "Amend payment Details";
    let optionConnect: string = "Can't connect to Medical Chat";
    let optionAccount: string = "Help with account";
    let optionOther: string = "Other";
    let optionProblem: string = "Problem with the app";
    let amountPayment: string = this.chats
      .filter((chat) => chat.firstTopic === optionPayment)
      .length.toString();
    let amountConnect: string = this.chats
      .filter((chat) => chat.firstTopic === optionConnect)
      .length.toString();
    let amountAccount: string = this.chats
      .filter((chat) => chat.firstTopic === optionAccount)
      .length.toString();
    let amountOther: string = this.chats
      .filter((chat) => chat.firstTopic === optionOther)
      .length.toString();
    let amountProblem: string = this.chats
      .filter((chat) => chat.firstTopic === optionProblem)
      .length.toString();
    return [
      [
        optionPayment,
        amountPayment + ` (${this.toPercentage(amountPayment)}%)`,
      ],
      [
        optionConnect,
        amountConnect + ` (${this.toPercentage(amountConnect)}%)`,
      ],
      [
        optionAccount,
        amountAccount + ` (${this.toPercentage(amountAccount)}%)`,
      ],
      [optionOther, amountOther + ` (${this.toPercentage(amountOther)}%)`],
      [
        optionProblem,
        amountProblem + ` (${this.toPercentage(amountProblem)}%)`,
      ],
    ];
  }

  toPercentage(value: string): string {
    return ((parseInt(value) / this.totalChats) * 100).toFixed(2).toString();
  }

  preferredLangReportsData() {
    let languages: (string | number)[][] = Array.from(
      new Set(this.chats)
    ).map((chat) => [
      chat.language,
      this.chats.filter((chat2) => chat2.language === chat.language).length,
    ]);
    let uniqueLanguageList: (string | number)[][] = Array.from(
      new Set(languages.map((language) => language[1]))
    ).map((languageCount) => {
      return [
        languages.find((lang) => lang[1] === languageCount)[0],
        languageCount,
      ];
    });

    const sortFunction = (a, b) => {
      if (a[1] === b[1]) {
        return a[1] < b[1] ? -1 : 1;
      } else {
        return 0;
      }
    };
    let topLangList: any[] = [];
    uniqueLanguageList.sort(sortFunction).map((lang, index) => {
      topLangList[index] = [
        index + 1,
        (lang[0] as string).charAt(0).toUpperCase() +
          (lang[0] as string).slice(1),
        lang[1] + ` (${this.toPercentage(lang[1].toString())}%)`,
      ];
    });
    return topLangList;
  }
}
